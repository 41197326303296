import React from "react"
import { Main } from "../components/layout"
import ProviderProfile from "../components/pages/provider/ProviderProfile"

const ProviderProfileHome = () => {
	return (
		<Main>
			<ProviderProfile />
		</Main>
	)
}

export default ProviderProfileHome
